<template>
  <collapse-transition>
    <div
      v-if="warningAlert"
      class="alert alert-info idle-warning text-center"
      role="alert"
    >
      <font-awesome-icon
        :icon="['far', 'stopwatch']"
        class="fa-lg"
      />
      {{ $t('idle.message', { time: countDown }) }}
    </div>
  </collapse-transition>

</template>
<script>
var counter;

export default {
  data: () => {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningAlert: false,
      countDown: 60,
    };
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    }, this);
    this.setTimers();
  },
  methods: {
    setTimers: function () {
      this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000); // 14 minutes = 14 * 60 * 1000
      this.logoutTimer = setTimeout(this.logout, 15 * 60 * 1000); // 15 minutes = 15 * 60 * 1000
      this.warningAlert = false;
      // reset countdown
      this.countDown = 60;
      clearTimeout(counter);
    },
    warningMessage: function () {
      this.warningAlert = true;
      this.countDownTimer();
    },
    resetTimers: function () {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    logout: function () {
      this.destroy();
      // Set logged OUT in store
      this.$store.commit("logIn", false);
      this.$router.push("/auth/login");
    },
    destroy() {
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimers);
      }, this);
    },
    countDownTimer() {
      if (this.countDown > 0) {
        counter = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>

<style>
.idle-warning {
  z-index: 1024;
  width: 100%;
  opacity: 0.9;
}
</style>